import { observable, action, runInAction, makeObservable } from 'mobx';

import {
  fetchCampaignRecipients,
} from 'Marketing/services/CampaignService';
import { handleErrorResponse } from 'App/services/utilities/error.utils';

const text = {
  FETCH_TEMPLATE_FAILED: 'Failed to fetch template',
  UPDATE_SUCCESS: 'Successfully updated template',
  UPDATE_FAILED: 'Failed to update template',
  CREATE_SUCCESS: 'Successfully created template',
  CREATE_DRAFT_SUCCESS: 'Template created as draft',
  CREATE_FAILED: 'Failed to create template',
  OPTIONS_FAILED: 'Failed to get fields data',
  TEST_MESSAGE_SUCCESS: 'Test message sent. Please check your inbox',
  TEST_MESSAGE_FAILED: 'Failed to send test email',
  START_SUCCESS: 'Successfully started template',
  START_FAILED: 'Failed to start template',
};

export default class CampaignsStore {
  constructor(queryBuilderStore) {
    makeObservable(this, {
      recipients: observable,
      fieldsDef: observable,
      requestInProgress: observable,
      errors: observable,
      fetchCampaignData: action.bound,
    });

    this.queryBuilder = queryBuilderStore;
  }

  recipients = {};

  fieldsDef = {};

  requestInProgress = {
    fetchRecipients: false,
  };

  errors = {};

  async fetchCampaignData(id) {
    this.requestInProgress.fetchRecipients = true;

    try {
      const response = await fetchCampaignRecipients(id);
      runInAction(() => {
        this.recipients = response.data;
      });
    } catch (err) {
      handleErrorResponse(err, text.FETCH_CAMPAIGN_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.fetchRecipients = false;
      });
    }
  }
}

import { observable, action, runInAction, makeObservable } from 'mobx';
import { omit } from 'lodash';
import {
  fetchCustomPropertiesOptions,
  fetchCustomProperty,
} from 'Settings/Sections/CustomProperties/services/CustomPropertiesService';
import stores from 'App/rootStore';
import { handleErrorResponse } from 'App/services/utilities/error.utils';

export default class CustomPropertiesStore {
  customProperty = {};

  fieldsDef = {};

  customPropertyFields = {};

  requestInProgress = {
    fetchCampaign: false,
    fetchFields: false,
  };

  errors = {};

  constructor() {
    makeObservable(this, {
      customProperty: observable,
      fieldsDef: observable,
      customPropertyFields: observable,
      requestInProgress: observable,
      errors: observable,
      fetchCustomPropertyFields: action.bound,
      setCustomPropertyData: action.bound,
    });
  }

  async fetchCustomPropertyFields() {
    const response = await fetchCustomPropertiesOptions();
    const fieldsToOmit = ['created_by', 'created', 'updated', 'updated_by'];
    const fieldsOptions = response.data.actions.POST;
    runInAction(() => {
      this.customPropertyFields = omit(fieldsOptions, fieldsToOmit);
    });
  }

  async setCustomPropertyData(customPropertyId) {
    runInAction(() => {
      this.requestInProgress.fetchFields = true;
    });
    try {
      const { data } = await fetchCustomProperty(customPropertyId);
      runInAction(() => {
        this.customProperty = data;
      });
      stores.forms.createEditCustomProperty.setFieldsData(data);
    } catch (err) {
      runInAction(() => {
        this.errors = err;
      });
      handleErrorResponse(err);
    } finally {
      runInAction(() => {
        this.requestInProgress.fetchFields = false;
      });
    }
  }
}

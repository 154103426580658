import React, { PureComponent } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { object, func } from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import NoDataDash from 'App/components/NoDataDash';
import Page from 'App/components/Page';
import Loader from 'App/components/Loader';
import { ContactCell, SideCell, StatusCell, WalletCell, ParentCell } from 'App/components/gridCellRenderers';
import { CardList, Row, Col, Code, Space } from 'tc-biq-design-system';
import compose from 'lodash/fp/compose';
import CopiedPositionsTable from 'Transactions/components/CopiedPositionsTable';
import PositionTransactionsTable from '../../components/PositionTransactionsTable';

import './PositionSinglePage.scss';

const propTypes = {
  match: object.isRequired,
  fetchPositionData: func.isRequired,
  positionData: object.isRequired,
};

const tableConfig = positionId => ({
  tableUrl: `/finances/trade-positions/${positionId}/transactions/`,
  tableKey: 'trades',
});

const bread = id => [
  { label: 'Transactions' },
  { label: 'Positions', route: '/transactions/positions' },
  { label: id, route: `/transactions/positions/${id}` },
];

const text = {
  POSITION: 'Position',
  EXTRA: 'Extra data',
};

const replaceLoDash = key => key.split('_').join(' ');
const uppercaseFirstLetter = key => `${key[0].toUpperCase()}${key.slice(1)}`;

const formatKey = compose(
  uppercaseFirstLetter,
  replaceLoDash,
);

const renderValue = (key, value) => {
  const date = moment(value, moment.ISO_8601);
  const dateFormat = 'YYYY-MM-DD HH:mm';
  if ((key === 'open_date' || key === 'close_date') && date.isValid()) return date.format(dateFormat);
  if (key === 'side' && value) return <SideCell value={value} />;
  if (key === 'state' && value) return <StatusCell value={value} />;
  if (key === 'pnl') {
    return value < 0 ? (
      <div className="text-status04-500">{value}</div>
    ) : (
      <div className="text-status01-500">{value}</div>
    );
  }
  if (key === 'trading_platform') return formatKey(value);
  if (key === 'parent') return <ParentCell data={value} routeHref="/transactions/positions" />;
  if (key === 'wallet') return <WalletCell data={value} />;
  return value ? value.toString() : '';
};

const generateFields = position => Object.keys(position).map(key => ({
  name: formatKey(key),
  value: renderValue(key, position[key]),
}));

class PositionSinglePage extends PureComponent {
  constructor(props) {
    super(props);
    this.id = props.match.params.positionId;
  }

  componentDidMount() {
    const { fetchPositionData } = this.props;
    fetchPositionData(this.id);
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params.positionId !== match.params.positionId) {
      const { fetchPositionData } = this.props;
      fetchPositionData(match.params.positionId);
    }
  }

  render() {
    const { positionData } = this.props;
    if (isEmpty(positionData)) return <Loader visible />;
    const { contact, metadata, external_id, parent_id, ...position } = positionData;
    const data = generateFields(position).filter(e => e.name !== 'Parent id');
    return (
      <Page
        bread={bread(this.id)}
        title={text.POSITION}
        style={{ marginTop: '24px', overflow: 'auto' }}
      >
        <Row gutter={20}>
          <Col xs="100%" md="35%">
            <div className="fiq-positions-single__details">
              <div className="head">
                {contact ? (
                  <ContactCell
                    fullName={`${contact.first_name}${contact.last_name}`}
                    id={contact.id}
                    avatar={contact.avatar}
                  />
                ) : (
                  <NoDataDash />
                )}
              </div>
              <div className="body">
                <CardList title={`${text.POSITION} (${external_id})`} items={data} />
              </div>
            </div>
          </Col>
          <Col xs="100%" md="65%">
            <div className="fiq-positions-single__metadata">
              <div className="tc-paragraph-strong">{text.EXTRA}</div>
              <Space size={24} />
              <div className="code">
                <Code data={metadata} />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="100%">
            <PositionTransactionsTable tableConfig={tableConfig(this.id)} />
          </Col>
        </Row>

        { !parent_id && (
          <Row>
            <Col xs="100%">
              <CopiedPositionsTable id={external_id} />
            </Col>
          </Row>
        ) }
      </Page>
    );
  }
}

PositionSinglePage.propTypes = propTypes;

export default withRouter(inject(stores => ({
  fetchPositionData: stores.transactions.fetchPositionData,
  positionData: stores.transactions.positionData,
}))(observer(PositionSinglePage)));

import { observable, action, runInAction, computed, makeObservable } from 'mobx';
import { omit, findIndex } from 'lodash';
import moment from 'moment';
import { notifier } from 'tc-biq-design-system';
import objectType from 'App/enums/objectType';

import {
  fetchActivity,
  fetchActivities,
  fetchActivityOptions,
  fetchContactActivities,
  fetchActivityComments,
  fetchActivityComment,
  addComment,
  editComment,
  deleteComment,
  fetchThread,
  fetchCampaignActivities,
  fetchSegmentActivities,
} from 'ActivityStream/services/activityStreamService';
import { parseParamString } from 'App/components/Filters/filterStoreUtils';
import { replaceMarkup, convertToMarkup } from 'App/components/CommentField';
import { handleErrorResponse } from 'App/services/utilities/error.utils';

const dateFormat = 'YYYY-MM-DD';
const initialValues = {
  defaultEvents: [
    'finances_tradetransaction',
    'finances_wallet',
    'finances_paymenttransaction',
    'communications_emaillog',
    'contacts_note',
  ],
  type: null,
  date: {
    startDate: moment()
      .subtract(1, 'years')
      .format(dateFormat),
    endDate: moment()
      .add(1, 'days')
      .format(dateFormat),
  },
};

const text = {
  GET: 'Failed to fetch Activity stream data!',
  OPTIONS: 'Failed to fetch Activity field config!',
  COMMENTS_FAILED: 'Failed to fetch comments',
  EDIT_COMMENT: 'Failed to edit comment',
  DELETE_COMMENT: 'Failed to delete comment',
  LOAD_THREAD_FAILED: 'Failed to load thread',
};

export default class ActivityStreamStore {
  constructor(FiltersStore) {
    makeObservable(this, {
      activities: observable,
      comments: observable,
      userActivites: observable,
      fieldsDef: observable,
      date: observable,
      type: observable,
      userId: observable,
      contactId: observable,
      campaignId: observable,
      segmentId: observable,
      initalFilters: observable,
      dateQuery: observable,
      typeQuery: observable,
      paginationQuery: observable,
      hasMore: observable,
      eventTypes: observable,
      newActivitiesCounter: observable,
      visibleIDs: observable,
      highlightIDs: observable,
      threads: observable,
      commentsPagination: observable,
      requestInProgress: observable,
      incrementCommentsCounter: action.bound,
      fetchActivities: action.bound,
      fetchActivityOptions: action.bound,
      updateActivity: action.bound,
      fetchActivityComments: action.bound,
      fetchPreviousComments: action.bound,
      addComment: action.bound,
      editComment: action.bound,
      deleteComment: action.bound,
      resetCommentsCursor: action.bound,
      setInitialFilters: action.bound,
      setDateQuery: action.bound,
      addTypeFilter: action.bound,
      resetActivities: action.bound,
      resetFilters: action.bound,
      query: computed,
      onNewActivity: action.bound,
      checkHighlight: action.bound,
      clearHighlights: action.bound,
      initThread: action.bound,
      loadMore: action.bound,
      hideThread: action.bound,
    });

    this.filters = FiltersStore;
  }

  activities = [];

  comments = [];

  userActivites = [];

  fieldsDef = {};

  date = {
    ...initialValues.date,
  };

  type = initialValues.type;

  userId = null;

  contactId = null;

  campaignId = null;

  segmentId = null;

  defaultEvents = initialValues.defaultEvents;

  initalFilters = {};

  dateQuery = {};

  typeQuery = {};

  paginationQuery = {
    cursor: '',
    limit: 10,
  };

  hasMore = false;

  eventTypes = [];

  newActivitiesCounter = 0;

  visibleIDs = new Set();

  highlightIDs = new Set();

  threads = {};

  commentsPagination = {
    query: {
      cursor: '',
      limit: 20,
      ordering: '-created',
    },
    previousQuery: {
      limit: 20,
      offset: 0,
      ordering: '-created',
    },
    hasMore: false,
    hasPrevious: false,
  };

  requestInProgress = {
    activityStream: false,
    fetchActivityComments: false,
    addComment: false,
    fetchPreviousComments: false,
  };

  incrementCommentsCounter(activity_id) {
    const index = findIndex(this.activities, { id: activity_id });
    this.activities[index] = {
      ...this.activities[index],
      comment_count: (this.activities[index].comment_count || 0) + 1,
    };
  }

  async fetchActivities(isScrollFetch) {
    if (!isScrollFetch) this.paginationQuery.cursor = '';
    this.requestInProgress.activityStream = true;
    try {
      let response = {};
      if (this.contactId) {
        response = await fetchContactActivities(this.contactId, this.query);
      } else if (this.campaignId) {
        response = await fetchCampaignActivities(this.campaignId, this.query);
      } else if (this.segmentId) {
        response = await fetchSegmentActivities(this.segmentId, this.query);
      } else {
        response = await fetchActivities(this.query);
      }
      const {
        next,
        results,
      } = response.data;
      const params = next ? parseParamString(next) : {};
      runInAction(() => {
        this.newActivitiesCounter = isScrollFetch ? this.newActivitiesCounter : 0;
        this.paginationQuery.cursor = params.cursor || '';
        results.forEach(item => this.visibleIDs.add(item.id));
        this.activities = isScrollFetch ? [...this.activities, ...results] : results;
        this.hasMore = !!next;
        this.requestInProgress.activityStream = false;
      });
    } catch (err) {
      handleErrorResponse(err, text.GET);
      runInAction(() => {
        this.requestInProgress.activityStream = false;
      });
    }
  }

  async fetchActivityOptions(fieldsToOmit = []) {
    try {
      const response = await fetchActivityOptions();
      runInAction(() => {
        this.fieldsDef = omit(response.data.actions.GET, [
          'object',
          'timestamp',
          'content',
          'id',
          ...fieldsToOmit,
        ]);
        this.filters.updateFields(this.fieldsDef);
        this.filters.updateViewName(response.data.view);
        this.eventTypes = formatEventTypes(response.data.actions.GET.object.related_models);
      });
    } catch (err) {
      handleErrorResponse(err, text.OPTIONS);
    }
  }

  async updateActivity(id) {
    try {
      const response = await fetchActivity(id);
      const activity = response.data;
      const existingActivityIndex = findIndex(this.activities, { id });
      if (existingActivityIndex !== -1) {
        runInAction(() => {
          this.activities[existingActivityIndex] = { ...activity };
        });
      }
    } catch (err) {
      handleErrorResponse(err, 'Failed to update activity');
    }
  }

  // Comments actions

  async fetchActivityComments({
    id,
    isOnScroll,
    commentId,
  }) {
    this.requestInProgress.fetchActivityComments = true;
    try {
      let response;
      if (commentId) {
        response = await fetchActivityComment(commentId);
      } else {
        response = await fetchActivityComments(id, this.commentsPagination.query);
      }
      const {
        next,
        previous,
        results,
      } = response.data;
      const params = next ? parseParamString(next) : {};
      runInAction(() => {
        this.commentsPagination.query.cursor = params.cursor || '';
        this.commentsPagination.hasMore = !!next;
        this.commentsPagination.hasPrevious = !!previous;
        const convertedResults = results.map(comment => ({
          ...comment,
          content: convertToMarkup(comment.content, comment.mentions),
        }));
        this.comments = isOnScroll ? [...this.comments, ...convertedResults] : convertedResults;
      });
    } catch (err) {
      handleErrorResponse(err, text.COMMENTS_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.fetchActivityComments = false;
      });
    }
  }

  async fetchPreviousComments({ commentId }) {
    this.requestInProgress.fetchPreviousComments = true;
    try {
      const response = await fetchActivityComment(commentId, this.commentsPagination.previousQuery);
      const {
        previous,
        results,
      } = response.data;
      const params = previous ? parseParamString(previous) : {};
      runInAction(() => {
        this.commentsPagination.previousQuery.offset = params.offset || '';
        this.commentsPagination.hasPrevious = !!previous;
        const convertedResults = results.map(comment => ({
          ...comment,
          content: convertToMarkup(comment.content, comment.mentions),
        }));
        this.comments = [...convertedResults, ...this.comments];
      });
    } catch (err) {
      handleErrorResponse(err, text.COMMENTS_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.fetchPreviousComments = false;
      });
    }
  }

  async addComment({
    id,
    content,
    messages,
  }) {
    this.requestInProgress.addComment = true;
    try {
      const response = await addComment(id, replaceMarkup(content));
      runInAction(() => {
        this.comments = [
          {
            ...response.data,
            content: convertToMarkup(response.data.content, response.data.mentions),
          },
          ...this.comments,
        ];
      });
      notifier.success(messages.SUCCESS);
    } catch (err) {
      handleErrorResponse(err, messages.ERROR);
    } finally {
      runInAction(() => {
        this.requestInProgress.addComment = false;
      });
    }
  }

  async editComment({
    id,
    commentId,
    content,
  }) {
    this.requestInProgress.editComment = true;
    try {
      const response = await editComment({
        id,
        commentId,
        content: replaceMarkup(content),
      });
      const indexOfEditedComment = findIndex(this.comments, { id: commentId });
      runInAction(() => {
        this.comments[indexOfEditedComment] = {
          ...response.data,
          content: convertToMarkup(response.data.content, response.data.mentions),
        };
      });
    } catch (err) {
      handleErrorResponse(err, text.EDIT_COMMENT);
    }
  }

  async deleteComment({
    id,
    commentId,
  }) {
    try {
      await deleteComment({
        id,
        commentId,
      });
      runInAction(() => {
        this.comments = this.comments.filter(comment => comment.id !== commentId);
      });
    } catch (err) {
      handleErrorResponse(err, text.DELETE_COMMENT);
    }
  }

  resetCommentsCursor() {
    this.commentsPagination.query.cursor = '';
  }

  // Filter actions

  setInitialFilters({
    userId,
    contactId,
    campaignId,
    segmentId,
  }) {
    this.initalFilters = userId ? { actor__users_user__id__exact: userId } : {};
    runInAction(() => {
      this.contactId = contactId;
      this.userId = userId;
      this.campaignId = campaignId;
      this.segmentId = segmentId;
    });
  }

  setDateQuery = (date) => {
    const {
      startDate,
      endDate,
    } = date;
    const startDateObject = moment.utc(startDate, dateFormat);
    const endDateObject = moment.utc(endDate, dateFormat);
    this.date = {
      startDate,
      endDate,
    };
    this.dateQuery = {
      timestamp__gt: startDateObject.format(dateFormat),
      timestamp__lt: endDateObject.add(1, 'days')
        .format(dateFormat),
    };
  };

  addTypeFilter(activeType) {
    this.type = activeType || null;
    if (activeType) {
      const { value } = activeType;
      const param = `object__${value}__id__isnull`;
      this.typeQuery = value ? { [param]: false } : {};
    }
  }

  resetActivities() {
    this.highlightIDs.clear();
    this.visibleIDs.clear();
    this.activities = [];
    this.userActivites = [];
  }

  resetFilters() {
    this.filters.resetFilters();
    this.typeQuery = {};
    this.date = { ...initialValues.date };
    this.contactId = null;
    this.campaignId = null;
    this.segmentId = null;
  }

  get query() {
    return {
      ...this.dateQuery,
      ...this.filters.query,
      ...this.paginationQuery,
      ...this.typeQuery,
      ...this.initalFilters,
    };
  }

  // New Activities actions

  onNewActivity(data) {
    if (this.type && this.type.value && data.object.object_type !== this.type.value) {
      return;
    }
    if (
      this.userId
      && data.actor.object_type === objectType.USER
      && data.actor.id !== this.userId
    ) {
      return;
    }
    if (this.contactId && data.contact_id !== this.contactId) {
      return;
    }
    const timestamp = moment.utc(data.timestamp);
    const {
      startDate,
      endDate,
    } = this.date;
    const startDateObject = moment.utc(startDate, dateFormat);
    const endDateObject = moment.utc(endDate, dateFormat);
    if (timestamp < startDateObject || timestamp > endDateObject) {
      return;
    }
    if (this.visibleIDs.size > 0 && !this.visibleIDs.has(data.id)) {
      runInAction(() => {
        this.newActivitiesCounter += 1;
        this.highlightIDs.add(data.id);
      });
    }
  }

  checkHighlight(id) {
    return this.highlightIDs.has(id);
  }

  clearHighlights() {
    this.visibleIDs.clear();
    this.highlightIDs.clear();
  }

  async initThread(threadId) {
    try {
      const thread = this.threads[threadId];

      if (!thread) {
        const res = await fetchThread({ threadId });

        runInAction(() => {
          this.threads = {
            ...this.threads,
            [threadId]: {
              activities: [...res.data.results],
              showThread: true,
              next: res.data.next,
              previous: res.data.previous,
              id: threadId,
            },
          };
        });
      } else {
        runInAction(() => {
          this.threads[threadId].showThread = true;
        });
      }
    } catch (e) {
      handleErrorResponse(e, text.LOAD_THREAD_FAILED);
    }
  }

  async loadMore(threadId, previousUrl, nextUrl) {
    try {
      const url = previousUrl || nextUrl;
      const res = await fetchThread({
        url: url
          .split('/')
          .slice(4)
          .join('/'),
      });

      runInAction(() => {
        this.threads = {
          ...this.threads,
          [threadId]: {
            activities: nextUrl
              ? [...this.threads[threadId].activities, ...res.data.results]
              : [...res.data.results, ...this.threads[threadId].activities],
            showThread: true,
            next: nextUrl ? res.data.next : this.threads[threadId].next,
            previous: previousUrl ? res.data.previous : this.threads[threadId].previous,
            id: threadId,
          },
        };
      });
    } catch (e) {
      handleErrorResponse(e, text.LOAD_THREAD_FAILED);
    }
  }

  hideThread(threadId) {
    this.threads[threadId].showThread = false;
  }
}

function formatEventTypes(rawEvents) {
  return rawEvents.map(({
    value,
    display_name,
  }) => ({
    value,
    displayName: display_name.replace('Log', '')
      .trim(),
  }));
}

import React from 'react';
import { object } from 'prop-types';
import NoDataDash from 'App/components/NoDataDash';

const propTypes = {
  value: object.isRequired,
};

const BrokerCell = ({ value }) => (
  <div className="truncate-text tc-paragraph-regular">{value?.name ?? <NoDataDash />}</div>
);

BrokerCell.propTypes = propTypes;
export default BrokerCell;

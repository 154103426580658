import React from 'react';

import {
  SideCell,
  RouteCell,
  WalletCell,
  StatusCell,
  AmountCell,
  ParentCell,
} from 'App/components/gridCellRenderers';

const modifier = {
  id: {
    pinned: 'left',
    cellRendererFramework: RouteCell('/transactions/trades/'),
  },
  side: {
    cellRendererFramework: SideCell,
  },
  position_effect: {
    cellRendererFramework: StatusCell,
  },
  wallet: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => (
      <WalletCell data={value} />
    ),
  },
  parent: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => (
      <ParentCell data={value} routeHref="/transactions/trades" />
    ),
  },
  amount: {
    cellRendererFramework: AmountCell,
  },
};

export default modifier;

import React from 'react';

import { Pill } from 'tc-biq-design-system';
import { AccountCell, RouteCell, AmountCell, BrokerCell } from 'App/components/gridCellRenderers';
import NoDataDash from 'App/components/NoDataDash';
import hideCols from 'App/services/utilities/hideCols';

export default {
  ...hideCols(['id', 'primary']),
  asset: {
    headerName: 'Wallet',
    cellRendererFramework: ({ data }) => RouteCell('/transactions/wallets/')({ hrefValue: data.id, value: data.asset }),
  },
  account: {
    cellRendererFramework: AccountCell,
    sortable: false,
  },
  broker: {
    cellRendererFramework: BrokerCell,
  },
  providerType: {
    // eslint-disable-next-line
    cellRendererFramework: ({ data }) => (
      <span className="tc-paragraph-regular">{data.account.provider_type}</span>
    ),
  },
  mode: {
    // eslint-disable-next-line
    cellRendererFramework: ({ data }) => data.account.account_type ? (
      <Pill type={data.account.account_type === 'Live' ? 'status01' : 'status03'}>
        {data.account.account_type}
      </Pill>
    ) : (
      <NoDataDash />
    ),
  },
  group: {
    // eslint-disable-next-line
    cellRendererFramework: ({ data }) => data.account.group ? (
      <span className="tc-paragraph-regular">{data.account.group}</span>
    ) : (
      <NoDataDash />
    ),
  },
  available: {
    cellRendererFramework: AmountCell,
  },
  reserved: {
    cellRendererFramework: AmountCell,
  },
  total: {
    cellRendererFramework: AmountCell,
  },
  debt: {
    cellRendererFramework: AmountCell,
  },
};

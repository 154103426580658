import http from 'App/services/http';

const fetchCampaignData = id => http.get(`/marketing/campaigns/${id}/`);

const createCampaign = payload => http.post('/marketing/campaigns/', payload);

const updateCampaign = (id, payload) => http.put(`/marketing/campaigns/${id}/`, payload);

const cloneCampaign = id => http.post(`/marketing/campaigns/${id}/clone/`);

const fetchCampaignsOptions = () => http.options('/marketing/campaigns/');

const startCampaign = id => http.post(`/marketing/campaigns/${id}/start/`);

const endCampaign = id => http.post(`/marketing/campaigns/${id}/end/`);

const deleteCampaign = id => http.delete(`/marketing/campaigns/${id}/`);

const sendTestEmail = payload => http.post('/marketing/campaigns/send-test-communication/', payload);

const fetchKPIs = id => http.get(`/marketing/campaigns/${id}/stats/`);

const fetchCampaignRecipients = id => http.get(`/marketing/campaigns/${id}/memberships/`);

const fetchCampaignsMetric = query => http.post('/marketing/campaigns/metric/', query);

const addWorkflowBlock = (campaignID, payload) => http.post(`/marketing/campaigns/${campaignID}/workflow/`, payload);

const editWorkflowBlock = (campaignID, blockID, payload) => http.put(`/marketing/campaigns/${campaignID}/workflow/${blockID}/`, payload);

const removeWorkflowBlock = (campaignID, blockID) => http.delete(`/marketing/campaigns/${campaignID}/workflow/${blockID}/`);

const moveWorkflowBlock = (campaignID, blockID, payload) => http.post(`/marketing/campaigns/${campaignID}/workflow/${blockID}/move/`, payload);

const getWorkflow = campaignID => http.get(`/marketing/campaigns/${campaignID}/workflow/`);

const fetchWorkflowPropsMetadata = () => http.get('/marketing/campaigns/workflow/props-metadata/');

const fetchJourneyStats = campaignID => http.get(`/marketing/campaigns/${campaignID}/journey-stats/`);

const fetchJourney = id => http.get(`/marketing/journeys/${id}/`);

export {
  fetchCampaignData,
  createCampaign,
  updateCampaign,
  cloneCampaign,
  sendTestEmail,
  fetchCampaignsOptions,
  startCampaign,
  endCampaign,
  deleteCampaign,
  fetchKPIs,
  fetchCampaignRecipients,
  fetchCampaignsMetric,
  addWorkflowBlock,
  fetchWorkflowPropsMetadata,
  editWorkflowBlock,
  removeWorkflowBlock,
  moveWorkflowBlock,
  getWorkflow,
  fetchJourneyStats,
  fetchJourney,
};

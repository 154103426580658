export default [
  {
    h: 4,
    i: 'incoming-funds',
    maxH: 4,
    maxW: 3,
    minH: 4,
    minW: 1,
    moved: false,
    static: true,
    w: 3,
    x: 3,
    y: 4,
  },
  {
    h: 4,
    i: 'total-incoming-funds',
    maxH: 4,
    maxW: 3,
    minH: 4,
    minW: 1,
    moved: false,
    static: true,
    w: 3,
    x: 0,
    y: 4,
  },
  {
    h: 4,
    i: 'outgoing-funds',
    maxH: 4,
    maxW: 3,
    minH: 4,
    minW: 1,
    moved: false,
    static: true,
    w: 3,
    x: 3,
    y: 10,
  },
  {
    h: 4,
    i: 'total-outgoing-funds',
    maxH: 4,
    maxW: 3,
    minH: 4,
    minW: 1,
    moved: false,
    static: true,
    w: 3,
    x: 0,
    y: 10,
  },
  {
    h: 2,
    i: 'incoming-count-numeric',
    maxH: 3,
    maxW: 3,
    minH: 1,
    minW: 1,
    moved: false,
    static: true,
    w: 1,
    x: 0,
    y: 2,
  },
  {
    h: 2,
    i: 'average-incoming-numeric',
    maxH: 3,
    maxW: 4,
    minH: 1,
    minW: 1,
    moved: false,
    static: true,
    w: 2,
    x: 0,
    y: 0,
  },
  {
    h: 2,
    i: 'average-ftd-numeric',
    maxH: 3,
    maxW: 4,
    minH: 1,
    minW: 1,
    moved: false,
    static: true,
    w: 2,
    x: 2,
    y: 0,
  },
  {
    h: 2,
    i: 'net-income-numeric',
    maxH: 3,
    maxW: 3,
    minH: 1,
    minW: 1,
    moved: false,
    static: true,
    w: 2,
    x: 4,
    y: 0,
  },
  {
    h: 2,
    i: 'ftd-count-numeric',
    maxH: 3,
    maxW: 3,
    minH: 1,
    minW: 1,
    moved: false,
    static: true,
    w: 1,
    x: 1,
    y: 2,
  },
  {
    h: 2,
    i: 'redeposits-count-numeric',
    maxH: 3,
    maxW: 3,
    minH: 1,
    minW: 1,
    moved: false,
    static: true,
    w: 1,
    x: 2,
    y: 2,
  },
  {
    h: 2,
    i: 'incoming-total-numeric',
    maxH: 3,
    maxW: 3,
    minH: 1,
    minW: 1,
    moved: false,
    static: true,
    w: 1,
    x: 3,
    y: 2,
  },
  {
    h: 2,
    i: 'ftd-total-numeric',
    maxH: 3,
    maxW: 3,
    minH: 1,
    minW: 1,
    moved: false,
    static: true,
    w: 1,
    x: 4,
    y: 2,
  },
  {
    h: 2,
    i: 'redeposits-total-numeric',
    maxH: 3,
    maxW: 3,
    minH: 1,
    minW: 1,
    moved: false,
    static: true,
    w: 1,
    x: 5,
    y: 2,
  },
  {
    h: 2,
    i: 'outgoing-total-numeric',
    maxH: 3,
    maxW: 3,
    minH: 1,
    minW: 1,
    moved: false,
    static: true,
    w: 2,
    x: 4,
    y: 8,
  },
  {
    h: 2,
    i: 'outgoing-count-numeric',
    maxH: 3,
    maxW: 3,
    minH: 1,
    minW: 1,
    moved: false,
    static: true,
    w: 2,
    x: 0,
    y: 8,
  },
  {
    h: 2,
    i: 'average-outgoing-numeric',
    maxH: 3,
    maxW: 4,
    minH: 1,
    minW: 1,
    moved: false,
    static: true,
    w: 2,
    x: 2,
    y: 8,
  },
  {
    h: 4,
    i: 'broker-trade-volume',
    maxH: 4,
    maxW: 3,
    minH: 4,
    minW: 1,
    moved: false,
    static: true,
    w: 3,
    x: 0,
    y: 14,
  },
];

import React, { useEffect, useState } from 'react';
import { Spinner, notifier, Button, InfoBox } from 'tc-biq-design-system';
import classnames from 'classnames';
import { object, func, bool, array } from 'prop-types';
import { openSelectBlockSidepanel } from 'Marketing/components/Workflow/overlays/SelectBlock/SelectBlock';
import { isEmpty, omit } from 'lodash';
import displayError from 'App/services/utilities/displayError';
import useStore from 'App/hooks/useStore';

import renderGraph from './libs/graph';
import SelectBlock, { closeSelectBlockSidepanel } from './overlays/SelectBlock/SelectBlock';
import EditBlock, { openEditBlockSidepanel, closeEditBlockSidepanel } from './overlays/EditBlock';

import { prepareData } from './libs/utils';
import { BLOCK_TYPE } from './libs/consts';
import './Workflow.scss';

const canvasId = `canvas-${Date.now()}`;

const propTypes = {
  blocks: object,
  workflow: object,
  onAddNode: func,
  onEditNode: func,
  onRemoveNode: func,
  onMoveNode: func,
  loading: bool,
  propFields: object,
  actions: array,
  isActive: bool,
  steps: array,
  isOverview: bool,
};

const defaultProps = {
  blocks: {},
  workflow: null,
  onAddNode: () => {
    // empty
  },
  onEditNode: () => {
    // empty
  },
  onRemoveNode: () => {
    // empty
  },
  onMoveNode: () => {
    // empty
  },
  loading: false,
  propFields: {
    // empty
  },
  actions: [],
  steps: null,
  isActive: false,
  isOverview: false,
};

const text = {
  NODE_ERROR: 'Something went wrong, contact support please.',
  CONDITION_EMPTY: "Query isn't defined.",
  CLONE_DESCRIPTION: 'Choose where you want to put cloned block, click on desired plus icon',
  CLONE_CANCEL: 'Cancel clone',
  MOVE_DESCRIPTION: 'Choose where you want to move selected block, click on desired plus icon',
  MOVE_CANCEL: 'Cancel move',
  DELETE_CANCEL: 'Cancel deletion',
  DELETE_DESCRIPTION: 'Choose where you want to connect blocks after deleting exit block, click on desired plus icon',
};

const isRulesEmpty = rules => !rules.length || rules.some(rule => isEmpty(rule));

const renderInfoBox = (action, handleOnClick) => {
  if (!action) return null;
  return (
    <div className="fiq-workflow__info-box">
      <InfoBox
        icon="Info"
        type="info"
      >
        <span>{text[`${action.toUpperCase()}_DESCRIPTION`]}</span>
        <Button
          color="transparent"
          onClick={handleOnClick}
        >
          <span>{text[`${action.toUpperCase()}_CANCEL`]}</span>
        </Button>
      </InfoBox>
    </div>
  );
};

const blockActions = {
  DELETE: 'delete',
  MOVE: 'move',
  CLONE: 'clone',
};

const Workflow = ({
  actions,
  blocks,
  propFields,
  workflow,
  onAddNode,
  onEditNode,
  onRemoveNode,
  onMoveNode,
  loading,
  isActive,
  steps,
  isOverview,
}) => {
  const [insertInfo, setInsertInfo] = useState(null);
  const [graph, setGraph] = useState(null);
  const [graphEvents, setGraphEvents] = useState(null);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [blockToFocus, setBlockToFocus] = useState(null);

  const [blockToCloneOrMoveStore, blockToCloneOrMove, setBlockToCloneOrMove] = useStore(null);

  const actionChoices = actions.map(action => ({
    value: action.actionID,
    display_name: action.name,
  }));

  const onNodeClone = (payload) => {
    const block = {
      ...blockToCloneOrMoveStore.current,
      name: `Copy of ${blockToCloneOrMoveStore.current.name}`,
    };
    onAddNode({ block, insertInfo: payload },
      addData => setBlockToFocus(addData.blocks[addData.blocks.length - 1].id));
    setBlockToCloneOrMove(null);
  };

  const onNodeMove = (blockId, payload) => {
    onMoveNode({ blockId, payload },
      addData => setBlockToFocus(addData.blocks[addData.blocks.length - 1].id));
    setBlockToCloneOrMove(null);
  };

  const onExitNodeDelete = (payload) => {
    onRemoveNode(blockToCloneOrMoveStore.current, payload);
    setBlockToCloneOrMove(null);
  };

  const handleSelectedGraphEdges = (payload) => {
    if (blockToCloneOrMoveStore.current) {
      const { workflowAction, id } = blockToCloneOrMoveStore.current;
      if (workflowAction === blockActions.CLONE) onNodeClone(payload);
      if (workflowAction === blockActions.MOVE) onNodeMove(id, payload);
      if (workflowAction === blockActions.DELETE) onExitNodeDelete(payload);
      return;
    }
    setInsertInfo(payload);
    if (payload) openSelectBlockSidepanel(payload.insert_exit_node);
  };

  const handleSelectedGraphNode = (node) => {
    if (node.disabled || node?.payload?.id === selectedBlock?.id) {
      return;
    }

    if (node && node.payload && node.blockType !== BLOCK_TYPE.EXIT) {
      closeEditBlockSidepanel();
      setSelectedBlock(node.payload);
      openEditBlockSidepanel(node.payload, insertInfo, onAddNode, isOverview);
    } else if (selectedBlock !== null) {
      setSelectedBlock(null);
      closeEditBlockSidepanel();
    }
  };

  const handleRemoveGraphNode = (node, edges) => {
    const findTargetEdge = targetNode => edge => edge.get('target').get('model').id === targetNode.id;
    const sourceEdge = edges.find(findTargetEdge(node));
    const findFirstNodeParent = (currentEdge) => {
      const source = currentEdge.get('source');
      const parent = source.get('model');
      if (parent.type !== 'workflowAddNode') {
        return parent.id;
      }

      const nextSourceEdge = source.get('edges').find(findTargetEdge(parent));

      return findFirstNodeParent(nextSourceEdge);
    };
    setBlockToFocus(findFirstNodeParent(sourceEdge));
    onRemoveNode(node);
  };

  const handleCloneGraphNode = (node) => {
    setBlockToCloneOrMove({ ...node.payload, workflowAction: blockActions.CLONE });
  };

  const handleGraphNodeCancel = () => {
    setBlockToCloneOrMove(null);
    deselectNodes();
  };

  const handleMoveGraphNode = (node) => {
    setBlockToCloneOrMove({ ...node.payload, workflowAction: blockActions.MOVE });
  };

  const deselectNodes = () => {
    graphEvents.resetNodes();
  };

  useEffect(() => { if (!isActive) closeEditBlockSidepanel(); }, [isActive]);

  // Initialize graph
  useEffect(() => {
    if (!isActive) return;
    if (!isEmpty(workflow) && !graph) {
      // Initialize graph
      const data = prepareData(workflow, steps, isOverview);
      const { graph: resp, graphEvents: events } = renderGraph(canvasId, data, {
        onEdgeSelected: handleSelectedGraphEdges,
        onNodeSelected: handleSelectedGraphNode,
        onNodeEdit: handleSelectedGraphNode,
        onNodeRemove: handleRemoveGraphNode,
        onNodeClone: handleCloneGraphNode,
        onNodeMove: handleMoveGraphNode,
      });
      setGraph(resp);
      setGraphEvents(events);
    } else if (!isEmpty(workflow) && graph) {
      // Update graph
      const data = prepareData(workflow, steps, isOverview);
      const focusNode = blockToFocus || graphEvents?.getRootNode()?.get('model').id;
      graphEvents.saveView(`${focusNode}`);
      setBlockToFocus(null);
      graph.read(data);
    }
  }, [workflow, isActive]);

  const handleSelectedBlock = (block) => {
    if (block && insertInfo) {
      if (block.type === BLOCK_TYPE.EXIT) {
        const maxExitNumber = Math.max(
          ...workflow.blocks
            .filter(b => b.type === BLOCK_TYPE.EXIT)
            .map((b) => {
              const numberMatch = b.name.match(/\d+/);
              return numberMatch ? Number(numberMatch[0]) : 1;
            }),
        );
        const name = `${block.name} #${maxExitNumber + 1}`;
        onAddNode({ block: { ...block, name, properties: {} }, insertInfo });
        closeSelectBlockSidepanel();
        return;
      }

      setSelectedBlock(block);
      closeSelectBlockSidepanel();
      openEditBlockSidepanel(block, insertInfo, onAddNode, isOverview);
    }
  };

  const handleAddBlock = async (blockData, setErrors) => {
    const { name, type, action_id, ...properties } = blockData;
    const isActionBlock = type === BLOCK_TYPE.ACTION;

    if (properties.condition && isRulesEmpty(properties.condition.rules)) {
      notifier.error(text.CONDITION_EMPTY);
      return;
    }

    setSubmitInProgress(true);

    const data = {
      name,
      type,
      properties: isActionBlock ? { action_id, action_parameters: properties } : { ...properties },
    };

    const [err] = await onAddNode({
      block: data,
      insertInfo,
    }, addData => setBlockToFocus(addData.blocks[addData.blocks.length - 1].id));

    if (err) {
      if (err?.response?.data) {
        const { action_parameters, ...rest } = err.response.data.data;
        const errors = isActionBlock ? { ...rest, ...action_parameters } : rest;
        setErrors(omit(errors, 'condition'));
        displayError(errors);
      } else {
        notifier.error(text.NODE_ERROR);
      }
    } else {
      closeSelectBlockSidepanel();
      setSelectedBlock(null);
    }
    setSubmitInProgress(false);
  };

  const handleEditedBlock = async (payload, setErrors) => {
    if (!payload) {
      setSelectedBlock(null);
      return;
    }
    const { name, action_id, ...properties } = payload;
    const isActionBlock = selectedBlock.type === BLOCK_TYPE.ACTION;

    if (properties.condition && isRulesEmpty(properties.condition.rules)) {
      notifier.error(text.CONDITION_EMPTY);
      return;
    }

    setSubmitInProgress(true);

    const data = {
      ...selectedBlock,
      name,
      properties: isActionBlock
        ? {
          action_id,
          action_parameters: properties,
        }
        : { ...properties },
    };
    setBlockToFocus(data.id);
    const err = await onEditNode(data);
    if (err) {
      if (err?.response?.data) {
        const errorData = err.response.data;
        const errors = isActionBlock
          ? { ...errorData, ...errorData?.action_parameters }
          : errorData;
        setErrors(omit(errors, 'condition'));
        displayError(errors);
      } else {
        notifier.error(text.NODE_ERROR);
      }
    } else {
      closeEditBlockSidepanel();
      setSelectedBlock(null);
    }
    setSubmitInProgress(false);
  };

  return (
    <div className={classnames('fiq-workflow', { 'fiq-workflow--loading': loading })}>
      <Spinner size="large" />
      <div className="fiq-workflow__canvas" id={canvasId} />
      {renderInfoBox(blockToCloneOrMove?.workflowAction, handleGraphNodeCancel)}
      <SelectBlock blocks={blocks} onBlockSelected={handleSelectedBlock} onClose={deselectNodes} />
      {selectedBlock && (
        <EditBlock
          actions={actions}
          allFields={propFields}
          setSelectedBlock={setSelectedBlock}
          onBlockEdited={handleEditedBlock}
          submitInProgress={submitInProgress}
          actionChoices={actionChoices}
          handleAddBlock={handleAddBlock}
          onClose={deselectNodes}
        />
      )}
    </div>
  );
};

Workflow.propTypes = propTypes;
Workflow.defaultProps = defaultProps;

export default Workflow;

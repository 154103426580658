import React from 'react';

import {
  SideCell,
  WalletCell,
  StatusCell,
  ContactCell,
  RouteCell,
  AmountCell,
  ParentCell,
} from 'App/components/gridCellRenderers';
import NoDataDash from 'App/components/NoDataDash';


const modifier = {
  id: {
    pinned: 'left',
    cellRendererFramework: RouteCell('/transactions/positions/'),
  },
  side: {
    cellRendererFramework: SideCell,
  },
  state: {
    cellRendererFramework: StatusCell,
  },
  wallet: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => (
      <WalletCell data={value} />
    ),
  },
  parent: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => (
      <ParentCell data={value} routeHref="/transactions/positions" />
    ),
  },
  contact: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => value ? (
      <ContactCell
        id={value.id}
        avatar={value.avatar}
        fullName={`${value.first_name} ${value.last_name}`}
      />
    ) : (
      <NoDataDash />
    ),
  },
  amount: {
    cellRendererFramework: AmountCell,
  },
};

export default modifier;

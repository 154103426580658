/* eslint-disable max-len */
import { observable, action, runInAction, makeObservable } from 'mobx';
import { find, sortBy } from 'lodash';

import errorFormatter from 'App/services/utilities/errorFormatter';

import RolesService from 'Settings/Sections/Roles/services/rolesService';
import PERMISSIONS_FIELD_TYPES from 'App/enums/permissionsFieldTypes';
import { notifier } from 'tc-biq-design-system';
import { handleErrorResponse } from 'App/services/utilities/error.utils';

const initialFieldsData = {
  name: '',
  searchTerm: '',
  accordions: { allVisible: false },
};

export default class RolesStore {
  constructor(manageUsersStore) {
    makeObservable(this, {
      rolesData: observable,
      filteredData: observable,
      fields: observable,
      allPermissions: observable,
      requestInProgress: observable,
      errors: observable,
      updateFieldValue: action.bound,
      resetFieldsValue: action.bound,
      fetchRolesData: action.bound,
      updateFieldPermission: action.bound,
      updateAllEndpoints: action.bound,
      updateEndpointPermission: action.bound,
      checkSelectedPermissions: action.bound,
      filterRolesData: action.bound,
      addEditRole: action.bound,
      deleteRole: action.bound,

    });
    this.manageUsers = manageUsersStore;
  }

  rolesData = [];

  filteredData = [];

  payload = {};

  fields = initialFieldsData

  allPermissions = {
    read: false,
    create: false,
    update: false,
    delete: false,
    export: false,
  }

  requestInProgress = {
    fetchData: false,
    fetchUsers: false,
    fetchBindUsers: false,
    addEditRole: false,
    deleteRole: false,
    activeUserId: 0,
  };

  errors = {
    fetchData: null,
    fetchUsers: null,
    fetchBindUsers: null,
    addEditRole: null,
    addRemoveRoleBinding: null,
  };

  updateFieldValue(key, value) {
    this.fields[key] = value;
    if (key === 'searchTerm') this.filterRolesData(value);
  }

  resetFieldsValue() {
    this.fields = initialFieldsData;
  }

  async fetchRolesData(id) {
    this.requestInProgress.fetchData = true;
    this.errors.fetchData = null;
    try {
      const response = await RolesService.fetchPermissionMetadata();
      let rolePermissions = null;
      if (id) {
        const roleResponse = await RolesService.fetchRoleData(id);
        rolePermissions = roleResponse.data.permissions;
        this.updateFieldValue('name', roleResponse.data.name);
      }
      runInAction(() => {
        this.rolesData = formatTableData(sortBy(response.data, ['content_type']), rolePermissions);
        this.filteredData = formatTableData(
          sortBy(response.data, ['content_type']),
          rolePermissions,
        );
        this.requestInProgress.fetchData = false;
      });
    } catch (err) {
      runInAction(() => {
        this.errors.fetchData = err.data;
        this.requestInProgress.fetchData = false;
      });
      handleErrorResponse(err);
    }
  }

  updateFieldPermission(endpointName, field, permission) {
    this.filteredData = this.filteredData.slice();
    const endpoint = find(this.filteredData, { name: endpointName });
    if (endpoint) {
      const currentFieldPermissions = find(endpoint.fieldPermissions, { id: field });
      Object.keys(currentFieldPermissions).forEach((key) => {
        if (typeof currentFieldPermissions[key] === 'boolean') {
          currentFieldPermissions[key] = key === permission;
        }
      });
    }
  }

  updateAllEndpoints(allEndpoints, permission, value) {
    this.allPermissions[permission] = value;
    allEndpoints.forEach(endpointName => this.updateEndpointPermission(endpointName, permission, value));
    this.filteredData = this.filteredData.slice();
  }

  updateEndpointPermission(endpointName, permission, value) {
    const endpoint = find(this.filteredData, { name: endpointName });
    endpoint.endpointPermissions[permission] = value;
    this.checkSelectedPermissions(endpoint);
  }

  checkSelectedPermissions(endpoint) {
    const { read, update, create } = endpoint.endpointPermissions;
    const { name, fieldPermissions, readOnlyFields } = endpoint;
    if (!read && (update || create)) {
      fieldPermissions.forEach((field) => {
        if (!readOnlyFields.includes(field.id)) {
          this.updateFieldPermission(name, field.id, PERMISSIONS_FIELD_TYPES.writeFields);
        }
      });
    }
    if (read && !update && !create) {
      fieldPermissions.forEach(field => this.updateFieldPermission(name, field.id, PERMISSIONS_FIELD_TYPES.readFields));
    }
    if (!read && !update && !create) {
      fieldPermissions.forEach(field => this.updateFieldPermission(name, field.id, PERMISSIONS_FIELD_TYPES.hiddenFields));
    }
  }

  filterRolesData(term) {
    if (!term) {
      this.filteredData = this.rolesData;
    } else {
      this.filteredData = this.rolesData.filter(role => role.name.toLowerCase().includes(term.toLowerCase()));
    }
  }

  async addEditRole({ onSuccess, id, isClone }) {
    this.requestInProgress.addEditRole = true;
    this.errors.addEditRole = null;
    const payload = {
      name: this.fields.name,
      permissions: formatPermissions(this.filteredData),
    };
    try {
      if (id && !isClone) {
        await RolesService.editRole(id, { id, ...payload });
      } else {
        await RolesService.addRole(payload);
      }
      runInAction(() => {
        this.requestInProgress.addEditRole = false;
        if (onSuccess) onSuccess();
      });
    } catch (err) {
      runInAction(() => {
        if (err.response && err.response.data.detail) {
          notifier.error(err.response.data.detail);
        } else {
          this.errors.addEditRole = errorFormatter(err?.response?.data);
        }
        this.requestInProgress.addEditRole = false;
        handleErrorResponse(err);
      });
    }
  }

  async deleteRole(id) {
    this.requestInProgress.deleteRole = true;
    try {
      await RolesService.deleteRole(id);
      runInAction(() => {
        this.requestInProgress.deleteRole = false;
      });
    } catch (err) {
      runInAction(() => {
        this.requestInProgress.deleteRole = false;
      });
      handleErrorResponse(err);
    }
  }
}

function formatTableData(data, predefinedData) {
  return data.map((endpoint) => {
    const endpointValues = find(predefinedData, { content_type: endpoint.content_type });
    return {
      ...endpoint,
      endpointPermissions: {
        read: (endpointValues && endpointValues.read) || false,
        create: (endpointValues && endpointValues.create) || false,
        update: (endpointValues && endpointValues.update) || false,
        delete: (endpointValues && endpointValues.delete) || false,
        export: (endpointValues && endpointValues.export) || false,
      },
      fieldPermissions: endpoint.fields.map((field) => {
        // Handle permissions for new custom properties
        if (endpointValues
          && !(endpointValues.read_fields.includes(field)
          || endpointValues.write_fields.includes(field)
          || endpointValues.hidden_fields.includes(field))) {
          const isWrite = endpointValues.create || endpointValues.update;
          const isRead = endpointValues.read;
          return {
            name: endpoint.name,
            id: field,
            field,
            read_fields: isRead && !isWrite,
            write_fields: isWrite,
            hidden_fields: !(isWrite || isRead),
          };
        }

        return {
          name: endpoint.name,
          id: field,
          field,
          read_fields: endpointValues ? endpointValues.read_fields.includes(field) : false,
          write_fields: endpointValues ? endpointValues.write_fields.includes(field) : false,
          hidden_fields: endpointValues ? endpointValues.hidden_fields.includes(field) : true,
        };
      }),
      readOnlyFields: endpoint.read_only_fields,
    };
  });
}

function extractFields(fieldPermissions, key) {
  return fieldPermissions.filter(field => field[key]).map(field => field.id);
}

function formatPermissions(data) {
  const permissions = [];
  data.forEach((endpoint) => {
    permissions.push({
      content_type: endpoint.content_type,
      ...endpoint.endpointPermissions,
      write_fields: extractFields(endpoint.fieldPermissions, PERMISSIONS_FIELD_TYPES.writeFields),
      read_fields: extractFields(endpoint.fieldPermissions, PERMISSIONS_FIELD_TYPES.readFields),
      hidden_fields: extractFields(endpoint.fieldPermissions, PERMISSIONS_FIELD_TYPES.hiddenFields),
    });
  });
  return permissions;
}

import React, { PureComponent } from 'react';
import { arrayOf, string } from 'prop-types';

import withErrorBoundary from 'App/components/hoc/withErrorBoundary';

import GridComponentFactory from 'App/components/grid';
import modifiers from './modifiers';

import './WalletFollowsTable.scss';

const propTypes = {
  follows: arrayOf(string).isRequired,
  external_id: string.isRequired,
};

const text = {
  WALLET_FOLLOWING: 'Wallet Following',
  WALLET_FOLLOWERS: 'Wallet Followers',
};

const tableConfig = (follows, external_id) => {
  let searchParams = '';
  if (Array.isArray(follows) && follows.length > 0) {
    searchParams = `?external_id__in=${follows.toString()}`;
  } else {
    searchParams = `?follows__has_any_keys=${external_id}`;
  }

  return {
    tableUrl: `/finances/wallets/${searchParams}`,
    tableKey: 'walletFollows',
  };
};

const sizeOptions = [5, 10, 50, 100, 200];

class WalletFollowsTable extends PureComponent {
  render() {
    const { follows, external_id } = this.props;
    const { GridComponent } = GridComponentFactory(tableConfig(follows, external_id));
    return (
      <div className="card-payments-wrapper">
        <div className="card-payments-wrapper__header">
          <span className="tc-heading-s">{follows ? text.WALLET_FOLLOWING : text.WALLET_FOLLOWERS}</span>
        </div>
        <div className="card-payments-wrapper__table">
          <GridComponent
            sizeOpitons={sizeOptions}
            height="20vh"
            modifiers={modifiers}
            defaultPageSize={5}
          />
        </div>
      </div>
    );
  }
}

WalletFollowsTable.propTypes = propTypes;
export default withErrorBoundary(WalletFollowsTable);

// Section components
import SignupDetails from './SignupDetails';
import KYC from './KYC';
import MIFID from './MIFID';
import Positions from './Positions';
import AccountsAndWallets from './AccountsAndWallets';
import Tickets from './Tickets';
import Notes from './Notes';
import ActivityStream from './ActivityStream';
import Documents from './Documents';
import Withdrawals from './Withdrawals';
import Payments from './Payments';
import Trades from './Trades';
import Cards from './Cards';
import BankAccounts from './BankAccounts';
import CustomProperties from './CustomProperties';
import Orders from './Orders';
import Referrals from './Referrals';
import Journeys from './Journeys';
import ContactFollowsTable from './ContactFollows';

const sections = [
  ActivityStream,
  Journeys,
  AccountsAndWallets,
  ContactFollowsTable,
  // Tickets, // TODO: uncomment when ticket comments view is implemented
  Trades,
  Payments,
  Cards,
  BankAccounts,
  Withdrawals,
  Positions,
  SignupDetails,
  KYC,
  MIFID,
  Notes,
  Documents,
  CustomProperties,
  Orders,
  Referrals,
];

export default sections;

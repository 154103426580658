import React, { PureComponent } from 'react';
import { number, oneOfType, string } from 'prop-types';

import withErrorBoundary from 'App/components/hoc/withErrorBoundary';

import GridComponentFactory from 'App/components/grid';
import modifiers from './modifiers';

import './CopiedPositionsTable.scss';

const propTypes = {
  id: oneOfType([number, string]).isRequired,
};

const text = {
  COPIED_POSITIONS: 'Copied Positions',
};

const tableConfig = externalId => ({
  tableUrl: `/finances/trade-positions/?parent_id__exact=${externalId}`,
  tableKey: 'copiedPositions',
});

const sizeOptions = [5, 10, 50, 100, 200];

class CopiedPositionsTable extends PureComponent {
  render() {
    const { id } = this.props;
    const { GridComponent } = GridComponentFactory(tableConfig(id));
    return (
      <div className="card-payments-wrapper">
        <div className="card-payments-wrapper__header">
          <span className="tc-heading-s">{text.COPIED_POSITIONS}</span>
        </div>
        <div className="card-payments-wrapper__table">
          <GridComponent
            sizeOpitons={sizeOptions}
            height="20vh"
            modifiers={modifiers}
            defaultPageSize={5}
          />
        </div>
      </div>
    );
  }
}

CopiedPositionsTable.propTypes = propTypes;
export default withErrorBoundary(CopiedPositionsTable);

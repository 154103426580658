import React, { PureComponent } from 'react';
import { arrayOf, oneOfType, string } from 'prop-types';

import GridComponentFactory from 'App/components/grid';
import withErrorBoundary from 'App/components/hoc/withErrorBoundary';
import modifiers from './modifiers';

const propTypes = {
  follows: oneOfType([arrayOf(string)]),
  externalId: oneOfType([string]),
};

const defaultProps = {
  follows: null,
  externalId: null,
};

const tableConfig = (follows, external_id) => {
  let searchParams = '';
  if (Array.isArray(follows) && follows.length > 0) {
    searchParams = `?external_id__in=${follows.toString()}`;
  } else {
    searchParams = `?follows__has_any_keys=${external_id}`;
  }

  return {
    tableUrl: `/contacts/${searchParams}`,
    tableKey: 'contactWalletFollows',
  };
};

const sizeOptions = [10, 20, 50, 100, 200];
const customColumns = [
  {
    key: 'providerType',
    headerName: 'Provider Type',
  },
  {
    key: 'mode',
    headerName: 'Mode',
  },
  {
    key: 'group',
    headerName: 'Group',
  },
];

class ContactFollowsTable extends PureComponent {
  render() {
    const { externalId, follows } = this.props;
    const { GridComponent } = GridComponentFactory(tableConfig(follows, externalId));
    return (
      <div>
        <GridComponent
          modifiers={modifiers}
          customColumns={customColumns}
          sizeOptions={sizeOptions}
          defaultPageSize={10}
          height="calc(100vh - 450px)"
        />
      </div>
    );
  }
}

ContactFollowsTable.propTypes = propTypes;
ContactFollowsTable.defaultProps = defaultProps;

export default withErrorBoundary(ContactFollowsTable);

import React from 'react';
import { object } from 'prop-types';
import { Link } from 'react-router-dom';
import { HyperLink } from 'tc-biq-design-system';

import NoDataDash from 'App/components/NoDataDash';

const propTypes = {
  data: object.isRequired,
};

const PARENT_STYLES = {
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

const ParentCell = ({ data, routeHref }) => {
  if (!data) return <NoDataDash />;
  return (
    <span className="tc-paragraph-regular">
      <HyperLink style={PARENT_STYLES}>
        <Link to={`${routeHref}/${data.id}`}>
          {data.id}
        </Link>
      </HyperLink>
    </span>
  );
};

ParentCell.propTypes = propTypes;
export default ParentCell;

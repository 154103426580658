import { action, observable, runInAction, makeObservable, observe } from 'mobx';
import { notifier } from 'tc-biq-design-system';

import stores from 'App/rootStore';
import {
  addNote,
  deleteNote,
  updateNote,
  fetchNotesData,
  fetchNoteData,
} from 'Contacts/services/contactsService';
import { parseParamString } from 'App/components/Filters/filterStoreUtils';
import { handleErrorResponse } from 'App/services/utilities/error.utils';

export default class NotesStore {
  notesData = [];

  requestInProgress = {
    notes: false,
    addNote: false,
    updateNote: false,
    deleteNote: false,
  };

  errors = {
    notes: null,
    updateNote: null,
    deleteNote: null,
    addNote: null,
  };

  pagination = {
    hasMore: false,
    query: {
      cursor: '',
      limit: 20,
      ordering: '-created',
    },
  };

  constructor() {
    makeObservable(this, {
      notesData: observable,
      requestInProgress: observable,
      errors: observable,
      pagination: observable,
      fetchNote: action.bound,
      fetchNotesData: action.bound,
      addNote: action.bound,
      updateNote: action.bound,
      deleteNote: action.bound,
    });
  }

  async fetchNote(contactId, noteId) {
    try {
      const res = await fetchNoteData(contactId, noteId);
      if (!this.notesData.find(n => n.id === res.data.id)) {
        runInAction(() => {
          this.notesData = [res.data, ...this.notesData];
        });
      }
    } catch (err) {
      handleErrorResponse(err);
    }
  }

  async fetchNotesData(id, isOnScroll) {
    this.requestInProgress.notes = true;
    try {
      const response = await fetchNotesData(id, this.pagination.query);
      const { next, results } = response.data;
      const { cursor } = next ? parseParamString(next) : '';
      runInAction(() => {
        this.pagination.hasMore = !!next;
        this.pagination.query.cursor = cursor;
        this.notesData = isOnScroll ? [...this.notesData, ...results] : results;
        this.requestInProgress.notes = false;
      });
    } catch (err) {
      runInAction(() => {
        this.errors = err;
        this.requestInProgress.notes = false;
      });
      handleErrorResponse(err);
    }
  }

  async addNote(fieldName) {
    this.requestInProgress.addNote = true;
    const { data, resetFieldsData, setFieldsErrors } = stores.forms.contactNoteForm;
    const { id } = stores.contact.profile.contactData;
    try {
      const response = await addNote(id, data[fieldName]);
      runInAction(() => {
        this.requestInProgress.addNote = false;
        resetFieldsData();
        this.notesData = [response.data, ...this.notesData];
        notifier.success('Note successfully added');
      });
    } catch (err) {
      runInAction(() => {
        setFieldsErrors(err);
        this.requestInProgress.addNote = false;
      });
      handleErrorResponse(err, 'Failed to add note');
    }
  }

  async updateNote(payload, noteIndex, contactId) {
    this.requestInProgress.updateNote = true;
    try {
      const { id } = this.notesData.slice()[noteIndex];
      const response = await updateNote(contactId, id, payload);
      runInAction(() => {
        this.notesData[noteIndex] = response.data;
        this.requestInProgress.updateNote = false;
      });
      notifier.success('Note successfully edited');
    } catch (err) {
      runInAction(() => {
        this.errors.updateNote = err.data;
        this.requestInProgress.updateNote = false;
      });
      handleErrorResponse(err, 'Failed to edit note');
    }
  }

  async deleteNote(contactId, id) {
    this.requestInProgress.deleteNote = true;
    try {
      await deleteNote(contactId, id);
      runInAction(() => {
        this.notesData = this.notesData.filter(note => note.id !== id);
        this.requestInProgress.deleteNote = false;
        notifier.success('Note deleted successfully!');
      });
    } catch (err) {
      runInAction(() => {
        this.errors.deleteNote = err;
        this.requestInProgress.deleteNote = false;
      });
      handleErrorResponse(err, 'Failed to delete note');
    }
  }
}

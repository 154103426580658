import {
  RouteCell,
  ContactCell, AmountCell, AccountCell, BrokerCell,
} from 'App/components/gridCellRenderers';
import React from 'react';

export default {
  created: {
    width: 160,
  },
  updated: {
    width: 160,
  },
  id: {
    pinned: 'left',
    cellRendererFramework: RouteCell('/transactions/wallets/'),
  },
  account: {
    cellRendererFramework: AccountCell,
    sortable: false,
  },
  broker: {
    cellRendererFramework: BrokerCell,
  },
  contact: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => (
      <ContactCell
        id={value.id}
        avatar={value.avatar}
        fullName={`${value.first_name} ${value.last_name}`}
      />
    ),
  },
  available: {
    cellRendererFramework: AmountCell,
  },
  reserved: {
    cellRendererFramework: AmountCell,
  },
  total: {
    cellRendererFramework: AmountCell,
  },
  debt: {
    cellRendererFramework: AmountCell,
  },
  sortable: false,
};

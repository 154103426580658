import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { object, func, bool, array } from 'prop-types';

import { notifier } from 'tc-biq-design-system';
import { fetchActions } from 'Automation/services/rulesService';
import { formatKey } from 'App/helpers/format';
import {
  addWorkflowBlock,
  fetchWorkflowPropsMetadata,
  editWorkflowBlock,
  removeWorkflowBlock,
  moveWorkflowBlock,
  getWorkflow,
} from 'Marketing/services/CampaignService';
import run from 'App/services/utilities/run';
import { fieldsResolver } from 'App/components/FieldRenderer';
import { handleErrorResponse } from 'App/services/utilities/error.utils';
import Workflow from '../Workflow';

const propTypes = {
  workflow: object.isRequired,
  match: object.isRequired,
  setWorkflow: func.isRequired,
  isActive: bool.isRequired,
  isOverview: bool,
  steps: array,
};

const defaultProps = {
  isOverview: false,
  steps: null,
};

const ActionType = 'Action';

const BlockMapping = {
  Delays: [
    {
      type: 'Time Delay',
      name: 'Time Delay',
    },
    {
      type: 'Wait Until',
      name: 'Wait Until...',
    },
  ],
  'Flow Control': [
    {
      type: 'Branch',
      name: 'True/False Branch',
    },
  ],
  Action: [
    {
      type: 'Action',
      name: 'Send Webhook',
      actionID: 'send_webhook',
    },
    {
      type: 'Action',
      name: 'Send Email',
      actionID: 'send_email_to_contact',
    },
    {
      type: 'Action',
      name: 'Send Whatsapp',
      actionID: 'send_whatsapp_message_to_contact',
    },
    {
      type: 'Action',
      name: 'Send SMS',
      actionID: 'send_sms_to_contact',
    },
    {
      type: 'Action',
      name: 'Action',
    },
  ],
  Exit: [
    {
      type: 'Exit',
      name: 'Exit',
    },
  ],
};

const CampaignWorkflow = ({
  workflow,
  match,
  setWorkflow,
  isActive,
  isOverview,
  steps,
}) => {
  const { campaignId } = match.params;
  const [workflowLoading, setWorkflowLoading] = useState(false);
  const [propFields, setPropFields] = useState(null);
  const [actions, setActions] = useState([]);

  const onAddNode = async (payload, onBeforeAdd = () => null) => {
    setWorkflowLoading(true);
    const [err, data] = await run(
      addWorkflowBlock(campaignId, {
        data: {
          ...payload.block,
        },
        ...payload.insertInfo,
      }),
    );
    setWorkflowLoading(false);
    if (!err) {
      onBeforeAdd(data);
      setWorkflow(data);
    }

    return [err, data];
  };

  const onEditNode = async (payload) => {
    const [err] = await run(editWorkflowBlock(campaignId, payload.id, payload));

    if (!err) {
      const { data } = await getWorkflow(campaignId);
      setWorkflow(data);
    }

    return err;
  };

  const onRemoveNode = async (payload) => {
    setWorkflowLoading(true);
    const [err, resp] = await run(removeWorkflowBlock(campaignId, payload.id));
    setWorkflowLoading(false);
    if (!err) {
      setWorkflow(resp);
    } else if (err.response.data) {
      handleErrorResponse(err);
    }
  };

  const onMoveNode = async ({ blockId, payload }, onBeforeAdd = () => {
    // empty
  }) => {
    setWorkflowLoading(true);
    const [err, data] = await run(
      moveWorkflowBlock(campaignId, blockId, {
        ...payload,
      }),
    );
    setWorkflowLoading(false);
    if (!err) {
      onBeforeAdd(data);
      setWorkflow(data);
    } else if (err.response.data) {
      handleErrorResponse(err);
    }

    return [err, data];
  };

  useEffect(() => {
    (async () => {
      const { data } = await fetchActions();
      const actionsArray = Object.keys(data).map(key => ({
        actionID: key,
        type: ActionType,
        name: formatKey(key),
        fields: data[key],
      }));
      setActions(actionsArray);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const propsMetadata = await fetchWorkflowPropsMetadata();
      const fields = Object.keys(propsMetadata.data).reduce((acc, type) => {
        acc[type] = fieldsResolver(propsMetadata.data[type]);
        return acc;
      }, {});
      setPropFields(fields);
    })();
  }, []);

  return (
    <Workflow
      loading={workflowLoading}
      blocks={BlockMapping}
      workflow={workflow}
      onAddNode={onAddNode}
      onEditNode={onEditNode}
      onRemoveNode={onRemoveNode}
      onMoveNode={onMoveNode}
      propFields={propFields}
      actions={actions}
      isActive={isActive}
      steps={steps}
      isOverview={isOverview}
    />
  );
};

CampaignWorkflow.propTypes = propTypes;
CampaignWorkflow.defaultProps = defaultProps;

export default inject(stores => ({
  workflow: stores.campaigns.workflow,
  setWorkflow: stores.campaigns.setWorkflow,
}))(observer(withRouter(CampaignWorkflow)));
